<template>
    <div class="dollar">
        <svg class="bill" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 344 146">
            <g id="Layer_2" data-name="Layer 2">
                <g id="Layer_2-2" data-name="Layer 2">
                    <rect class="cls-1" width="344" height="146" />
                </g>
                <g id="Layer_3" data-name="Layer 3">
                    <rect class="cls-2" x="7.54" y="7.35" width="329.36" height="132.59" />
                    <g id="Layer_4" data-name="Layer 4">
                        <path class="cls-3" d="M53.39,15.14H292s-1.69,3.09-1.43,4.52c-3,0-3.11,4.15-2.21,6s8.57,9.61,9.09,10.26c-4.8-2.21-8.31-.78-8.31-.78s.39,7.53,6.24,9.09c-1.56,3.37-2.86,8.7-2.21,9.34s9.61-2.85,9.61-5.06c0,5.06-.52,6.23,1.42,6.62a7.59,7.59,0,0,0,3.3,0,11.75,11.75,0,0,0,2.89,7.95c1.71,1.84,5.38,4.85,5.38,4.85-5.77-3.15-6.82-3.15-7.35-2.75.13,3,5.91,9.58,7.35,10.11a17.46,17.46,0,0,0,0,5.38c-2.88-2.37-5-3.28-6-2.5s.92,8.27,5.39,10.77c0,6.17-.13,15.27-.13,15.27-5.23,2.34-11.87,6-11,23.5H245.84c-.41-1.17-3.54-3-4.17-2.84.43-1.78-1-4.62-1.76-4.62a6.25,6.25,0,0,0-3.28,2.26c-2.7.07-17.8-.15-17.8-.15s4.43-1.43,4.15-2.58-3-3.5-4.15-3.64-1.36-.5-1.36-.5c1.43-.93,2.65-2.5,1.72-2.65s-5.79-1-7-.14c1.5-2,3.65-6.94,3.5-7.29s-2.43-1.22-2.86-.72-.21-5.93-1.28-6.15-1.5.51-1.79.58,1-17,.59-20.12-6.57-23.5-7.13-24.64-.73-3.65,0-4.21,2,1,3,.81,2-3.81,1.38-4.87l0-7.78H136.79l-.06,1.45-.1,9.52s.46.69,2.08-.12,1.91-4.69,2.95-4.69,4.52,2.66,3.53,5.38-9,16.78-10.18,27.55-.57,20.12.48,22.77c-2.93-.53-3.34-1.3-3.64.47a16.11,16.11,0,0,0,.24,5.4l.29,1.17c-2.4-.7-4.86,3.17-3.11,4.26-4.06,1.18-5.9,2.41-6.33,3.78s3.94,5.43,6.22,6.31H107.33s-2.85-2.07-4.15-1.91-2.85,2.85-1.79,4.48c-2.43.94-4.23,3.1-3.8,4.18H38.77c2.94-5.44-1.58-27.08-9.87-27.08-.11,0,0-28.35,0-28.35,2.26-1.08,5.19-7,4-9.6,3.51-2.93,11.67-12.52,12-16.88,3.44,1.43,3.44,4.86,5.44,4.93s2.93-4.86,2.86-7c4.72-1.64,4.37-.14,6.23,0s2.36-1.79,1.93-3.22-6.08-7.15-11-6.72c2.43-3,4.44-10.09,4-11.59s-2.15-1.27-2.7-1.27C52.43,19.2,53.88,17.28,53.39,15.14Z" />
                    </g>
                </g>
                <g id="Layer_5" data-name="Layer 5"><text class="cls-4" transform="translate(57.9 28.21)">
                        <tspan class="cls-5">T</tspan>
                        <tspan x="8.28" y="0">HE UN</tspan>
                        <tspan class="cls-6" x="49.52" y="0">I</tspan>
                        <tspan class="cls-5" x="55.07" y="0">T</tspan>
                        <tspan x="63.35" y="0">ED </tspan>
                        <tspan class="cls-7" x="85.83" y="0">S</tspan>
                        <tspan class="cls-8" x="93.43" y="0">T</tspan>
                        <tspan class="cls-9" x="101.46" y="0">A</tspan>
                        <tspan class="cls-5" x="110.2" y="0">T</tspan>
                        <tspan class="cls-10" x="118.48" y="0">E</tspan>
                        <tspan x="127.65" y="0">S OF AME</tspan>
                        <tspan class="cls-11" x="189.71" y="0">R</tspan>
                        <tspan x="199.77" y="0">I</tspan>
                        <tspan class="cls-12" x="205.23" y="0">C</tspan>
                        <tspan x="214.67" y="0">A</tspan>
                    </text><text class="cls-13" transform="translate(109.99 134.38) scale(1.5 1)">ONE DOL<tspan class="cls-14" x="56.02" y="0">L</tspan>
                        <tspan x="64.36" y="0">AR</tspan>
                    </text>
                    <g id="Layer_6" data-name="Layer 6"><text class="cls-15" transform="translate(20.76 50.41) scale(0.75 1)">1</text><text class="cls-15" transform="translate(307.99 48.48) scale(0.75 1)">1</text><text class="cls-16" transform="translate(18.82 126.91)">1</text><text class="cls-16" transform="translate(311.59 126.91)">1</text>
                        <g id="Layer_8" data-name="Layer 8">
                            <path class="cls-17" d="M139.4,98.13c.42-.53,5-7.28,8-7.84s9.09-3.35,9.09-3.35,25.32,7.23,26.4,8.37,3.74,3.79,5.18,5.45,8.2,3.71,10.12,5.15a31.53,31.53,0,0,1,3.14,2.65c-1.82,3.11-6.89,10.61-8.49,12.12s-6.28.79-10.07.64-9.54-.41-13.78-.34-16.89,2.2-18,1.67S135.62,102.87,139.4,98.13Z" />
                        </g>
                        <path class="cls-18" d="M156.52,86.94V77.11c-1.93-.2-2.7-4.11-2.7-4.11-2.5.44-11.18-2.45-10.22-4s3.67-7.14,5.21-7.14c.77-3.08,7.52-13.88,13.88-15.81s13.69-3.47,19.48,1.16S192.6,61.38,193,63.08s1.22,4.21,2.43,5.18,3.08,4.21,2.76,5.75-3.32,2.18-4.21,2.34c-2.27,2.11-3.89,4-4.7,6.08S185,89.55,184,90.12s-1.14,6.07-1.14,6.07A23.74,23.74,0,0,0,181,99.76c0,.48,6.88,9.71,6.88,10.77s1,8.66.08,9.47-13.51,1.62-14.89,1.21-9.56-18.7-9-20.08,1.05-3.07.56-4S156.52,88.46,156.52,86.94Z" />
                        <g id="Layer_7" data-name="Layer 7">
                            <line class="cls-19" x1="174.42" y1="81.45" x2="186.8" y2="81.45" />
                            <path class="cls-19" d="M177.28,74.1a7.85,7.85,0,0,0,4.14,3.46,5.42,5.42,0,0,0,3.25-1.09" />
                            <path class="cls-19" d="M168.33,64.53c2.1-1,4.75-2.3,6.51-1.89s3.8,2.64,4.34,4.13a13.4,13.4,0,0,1,.95,3" />
                            <path class="cls-19" d="M183.79,65.42a3.11,3.11,0,0,1,2.65-1.36A4.44,4.44,0,0,1,189,65.42" />
                            <line class="cls-19" x1="184.13" y1="66.57" x2="187.52" y2="66.57" />
                            <line class="cls-19" x1="172.94" y1="66.57" x2="170.91" y2="66.57" />
                            <rect class="cls-20" x="87.06" y="8.66" width="170.3" height="7.22" />
                        </g>
                    </g><text class="cls-21" transform="translate(86.64 14.32) scale(2 1)">FE<tspan class="cls-22" x="8.28" y="0">D</tspan>
                        <tspan class="cls-23" x="13.18" y="0">E</tspan>
                        <tspan class="cls-24" x="17.53" y="0">R</tspan>
                        <tspan x="22.58" y="0">AL </tspan>
                        <tspan class="cls-25" x="32.7" y="0">R</tspan>
                        <tspan class="cls-26" x="37.57" y="0">E</tspan>
                        <tspan x="42.01" y="0">SE</tspan>
                        <tspan class="cls-27" x="50.13" y="0">R</tspan>
                        <tspan class="cls-28" x="54.82" y="0">V</tspan>
                        <tspan x="59.33" y="0">E </tspan>
                        <tspan class="cls-29" x="65.25" y="0">N</tspan>
                        <tspan class="cls-30" x="69.61" y="0">O</tspan>
                        <tspan class="cls-31" x="74.58" y="0">T</tspan>
                        <tspan x="78.59" y="0">E</tspan>
                    </text>
                </g>
            </g>
        </svg>
    </div>
</template>
<script>
export default {
    name: "Dollar"
}
</script>
<style scoped lang="scss">
.dollar {
    width: 300px;
    height: 100px;
}
.bill {
    .cls-1 {
        fill: #e6eaca;
    }

    .cls-2 {
        fill: #787a71;
    }

    .cls-13,
    .cls-3 {
        fill: #eceecd;
    }

    .cls-4 {
        font-size: 13px;
        fill: #535549;
    }

    .cls-13,
    .cls-15,
    .cls-16,
    .cls-21,
    .cls-4 {
        font-family: BodoniURW-Bol, Bodoni URW;
    }

    .cls-5 {
        letter-spacing: 0.01em;
    }

    .cls-6 {
        letter-spacing: 0.01em;
    }

    .cls-7 {
        letter-spacing: -0.01em;
    }

    .cls-8 {
        letter-spacing: -0.01em;
    }

    .cls-9 {
        letter-spacing: -0.01em;
    }

    .cls-10 {
        letter-spacing: 0.01em;
    }

    .cls-11 {
        letter-spacing: 0em;
    }

    .cls-12 {
        letter-spacing: 0.02em;
    }

    .cls-13 {
        font-size: 12px;
    }

    .cls-14 {
        letter-spacing: 0.03em;
    }

    .cls-15 {
        font-size: 36px;
    }

    .cls-15,
    .cls-16 {
        fill: #e8ebc9;
    }

    .cls-16 {
        font-size: 21.6px;
    }

    .cls-17 {
        fill: #959593;
    }

    .cls-18 {
        fill: #c5c9b2;
    }

    .cls-19 {
        fill: none;
        stroke: #767965;
        stroke-miterlimit: 10;
    }

    .cls-20 {
        fill: #2f312c;
    }

    .cls-21 {
        font-size: 6.3px;
        fill: #e6e9c7;
    }

    .cls-22 {
        letter-spacing: -0.01em;
    }

    .cls-23 {
        letter-spacing: 0em;
    }

    .cls-24 {
        letter-spacing: 0.03em;
    }

    .cls-25 {
        letter-spacing: 0em;
    }

    .cls-26 {
        letter-spacing: 0.01em;
    }

    .cls-27 {
        letter-spacing: -0.03em;
    }

    .cls-28 {
        letter-spacing: 0.02em;
    }

    .cls-29 {
        letter-spacing: -0.01em;
    }

    .cls-30 {
        letter-spacing: 0em;
    }

    .cls-31 {
        letter-spacing: 0.01em;
    }
}
</style>