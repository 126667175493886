<template>
    <div>
    <svg xmlns="http://www.w3.org/2000/svg">
    <g class="checkbox" fill="none" fill-rule="evenodd" stroke="darkorange" >
        <g id="Group" stroke="#red" stroke-width="6">
            <path d="M0,26.159668 C5.2265625,29.0917969 7.90332031,31.265625 12.171875,40.9121094" id="Path" stroke-linejoin="round"></path>
            <path d="M13.171875,40.9121094 C20.1032854,32.1218132 32.8535156,6.54003906 42,0" id="Path-2"></path>
        </g>
        <g id="Group" stroke="#red" stroke-width="6" transform="translate(30, 0)">
            <path d="M0,26.159668 C5.2265625,29.0917969 7.90332031,31.265625 12.171875,40.9121094" id="Path" stroke-linejoin="round"></path>
            <path d="M13.171875,40.9121094 C20.1032854,32.1218132 32.8535156,6.54003906 42,0" id="Path-2"></path>
        </g>
    </g>
    </svg>
</div>
</template>
<script>
export default {

}
</script>